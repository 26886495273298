import React, { useEffect } from "react";
import { useState } from "react";
import Center from "./Center";
import Left from "./Left";
import ModalRoadMap from "./ModalRoadMap";
import Right from "./Right";
import NewLeft from "../NewItemRoadMap/ChildItem/NewLeft";
import NewRight from "../NewItemRoadMap/ChildItem/NewRight";
import dataCyber from "./../../../../../dataFake/dataBCFE48.json";
import NewCenter from "../NewItemRoadMap/ChildItem/NewCenter";
import { useSelector } from "react-redux";
export default function ItemRoadMap({ data, index, dataHash, indexChuongHoc }) {
  let { dataTaskRoadMap } = useSelector((state) => state.roadmapReducer);
  // console.log(Object.keys({}).length);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [contentModal, setContentModal] = useState();
  // console.log(dataHash);

  const checkObject = (obj) => {
    let count = 0;
    for (var key in obj) {
      if (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0) {
        count++; // If it's empty, continue to the next sub-object
      }
    }
    if (count === Object.keys(obj).length) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    // const checkHoanThanh = new Map(Object.entries(data));
  }, []);
  // const checkShowRight = () => {
  //   if(dataHash == undefined){
  //     if (data) {
  //       return false
  //     }
  //   }
  // };
  return (
    <div className="w-full  space-x-12 xl:space-x-36 flex  justify-center items-center transform -translate-y-12 relative">
      <ModalRoadMap
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title={titleModal}
        ContentComponent={contentModal}
        setContentModal={setContentModal}
      />
      {dataHash == undefined && dataTaskRoadMap.size !== 0 ? (
        <NewLeft
          isShow={
            data.taiLieuBaiHoc.length > 0 ||
            // data.taiLieuBaiTap.length > 0 ||
            data.videoXemLai.length > 0
          }
          data={data}
          dataHash={dataHash}
          setTitleModal={setTitleModal}
          setIsModalVisible={setIsModalVisible}
          setContentModal={setContentModal}
        />
      ) : dataHash == undefined && dataTaskRoadMap.size == 0 ? (
        <Left
          isShow={
            data.taiLieuBaiHoc.length > 0 ||
            data.taiLieuBaiTap.length > 0 ||
            data.videoXemLai.length > 0
          }
          setTitleModal={setTitleModal}
          setIsModalVisible={setIsModalVisible}
          data={data}
          setContentModal={setContentModal}
        />
      ) : (
        ""
      )}
      {dataHash && dataTaskRoadMap ? (
        <NewLeft
          isShow={
            data.taiLieuBaiHoc.length > 0 ||
            // data.taiLieuBaiTap.length > 0 ||
            data.videoXemLai.length > 0
          }
          data={data}
          dataHash={dataHash}
          setTitleModal={setTitleModal}
          setIsModalVisible={setIsModalVisible}
          setContentModal={setContentModal}
        />
      ) : (
        ""
      )}
      {dataTaskRoadMap.size == 0 ? (
        <Center
          isShowLeft={
            data.taiLieuBaiHoc.length > 0 ||
            data.taiLieuBaiTap.length > 0 ||
            data.videoXemLai.length > 0
          }
          isShowRight={
            data.videoExtra.length > 0 ||
            data.taiLieuDocThem.length > 0 ||
            data.taiLieuProjectLamThem.length > 0
          }
          data={data}
          index={index}
        />
      ) : dataTaskRoadMap.size !== 0 ? (
        <NewCenter
          isShowLeft={
            data.taiLieuBaiHoc.length > 0 ||
            // data.taiLieuBaiTap.length > 0 ||
            data.videoXemLai.length > 0
          }
          isShowRight={
            data.videoExtra.length > 0 ||
            data.taiLieuBaiTap.length > 0 ||
            data.taiLieuDocThem.length > 0 ||
            data.tracNghiem.length > 0 ||
            data.taiLieuProjectLamThem.length > 0
            // data && checkObject(dataHash)

            // (dataHash && dataTaskRoadMap.size !== 0)
            // false
          }
          data={data}
          index={index}
        />
      ) : (
        ""
      )}
      {dataHash == undefined && dataTaskRoadMap.size !== 0 ? (
        // <NewRight
        //   data={data}
        //   dataHash={dataHash}
        //   setTitleModal={setTitleModal}
        //   setIsModalVisible={setIsModalVisible}
        //   setContentModal={setContentModal}
        // />
        <NewRight
          isShow={
            data.videoExtra.length > 0 ||
            data.taiLieuDocThem.length > 0 ||
            data.taiLieuProjectLamThem.length > 0 ||
            data.taiLieuBaiTap.length > 0 ||
            data.taiLieuCapstone.length > 0 ||
            data.tracNghiem.length > 0
          }
          data={data}
          dataHash={dataHash}
          setTitleModal={setTitleModal}
          setIsModalVisible={setIsModalVisible}
          setContentModal={setContentModal}
          indexChuongHoc={indexChuongHoc}
        />
      ) : // <div className=" w-60 xl:w-96 h-32 min-h-280"></div>
      dataHash == undefined && dataTaskRoadMap.size == 0 ? (
        <Right
          isShow={
            data.videoExtra.length > 0 ||
            data.taiLieuDocThem.length > 0 ||
            data.taiLieuProjectLamThem.length > 0
          }
          setTitleModal={setTitleModal}
          setIsModalVisible={setIsModalVisible}
          data={data}
          setContentModal={setContentModal}
        />
      ) : (
        ""
      )}
      {dataHash && (
        <NewRight
          isShow={
            data.videoExtra.length > 0 ||
            data.taiLieuDocThem.length > 0 ||
            data.taiLieuProjectLamThem.length > 0 ||
            data.taiLieuBaiTap.length > 0 ||
            data.taiLieuCapstone.length > 0 ||
            data.tracNghiem.length > 0 ||
            data.runCode.length > 0
          }
          data={data}
          dataHash={dataHash}
          setTitleModal={setTitleModal}
          setIsModalVisible={setIsModalVisible}
          setContentModal={setContentModal}
          indexChuongHoc={indexChuongHoc}
        />
      )}
      {/* {!dataHash && (
        <Right
          isShow={
            data.videoExtra.length > 0 ||
            data.taiLieuDocThem.length > 0 ||
            data.taiLieuProjectLamThem.length > 0
          }
          setTitleModal={setTitleModal}
          setIsModalVisible={setIsModalVisible}
          data={data}
          setContentModal={setContentModal}
        />
      )} */}
    </div>
  );
}
