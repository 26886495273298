import React from "react";
import { Progress } from "antd";

export default function HeaderCollapse({ data }) {
  return (
    <div className=" flex w-full justify-center  items-center  ">
      <div className="w-22 h-22  relative ">
        <Progress
          type="circle"
          strokeColor={{
            "0%": "#fb8085",
            "100%": "#f53844",
          }}
          percent={data.diemBuoiHoc.phanTramCam}
          showInfo={false}
          className="absolute w-full h-full center-position scale-110"
          trailColor={"rgba(68, 66, 178, 0.1)"}
        />
        {/* <Progress
          type="circle"
          strokeColor={{
            "0%": "#5B86E5",
            "100%": "#36D1DC",
          }}
          percent={data.diemBuoiHoc.phanTramTim}
          showInfo={false}
          className="absolute w-full h-full center-position scale-125 origin-center rotate-180"
          trailColor={"rgba(68, 66, 178, 0.1)"}
        /> */}
        <div className=" h-full w-fu rounded-full card_theme flex items-center">
          <span className="p-1  text-center w-full">{data.tenSkill}</span>
        </div>
        {/* left */}
        {/* <div
          style={{ left: "-47px", bottom: "-4px", background: "#5B86E5" }}
          className="absolute  w-6 h-0.5 "
        >
          <span
            style={{ color: "#5B86E5" }}
            className="absolute bottom-1 l-0 w-full text-center text-xs"
          >
            {Math.floor(data.diemBuoiHoc.phanTramTim)}%
          </span>
        </div> */}
        {/* <div
          style={{
            transform: "rotate(-40deg)",
            left: "-27px",
            bottom: "5px",
            background: "#5B86E5",
          }}
          className="absolute  w-7 h-0.5  "
        ></div> */}
        {/* right */}
        <div
          style={{ right: "-47px", top: "-4px", background: "#fb8085" }}
          className="absolute  w-6 h-0.5 "
        >
          <span
            style={{ color: "#fb8085" }}
            className="absolute bottom-1 l-0 w-full text-center text-xs"
          >
            {Math.floor(data.diemBuoiHoc.phanTramCam)}%
            {/* {Math.floor(data.diemBuoiHoc.phanTramTim)}% */}
          </span>
        </div>
        <div
          style={{
            transform: "rotate(-40deg)",
            right: "-27px",
            top: "5px",
            // background: "#5B86E5",
            background: "#fb8085",
          }}
          className="absolute  w-7 h-0.5  "
        ></div>
      </div>
    </div>
  );
}
