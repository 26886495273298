import React from "react";
import TracNghiem_Start from "./TracNghiem_Answer";

export default function ConTentXemDapAn({ data }) {
  return (
    <div style={{ width: "90vw", height: "85vh" }}>
      <TracNghiem_Start data={data} />
    </div>
  );
}
