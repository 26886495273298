import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useMonaco } from "@monaco-editor/react";
import "./selectLanguages.scss";
const { Option } = Select;

export default function SelectLanguages({ setLanguages }) {
  // const monaco = useMonaco();
  const languages = [
    { name: "JavaScript", value: "javascript" },
    { name: "C++", value: "cpp" },
    { name: "Java", value: "java" },
    { name: "Python", value: "py" },
  ];

  // console.log(monaco);
  return (
    <>
      <Select
        id="select-language"
        style={{ width: 120 }}
        defaultValue={languages[0].value}
        onSelect={(value) => setLanguages(value)}
      >
        {/* {languages.map((lang, index) => {
          return (
            <Option key={index} value={lang.value}>
              {lang.name}
            </Option>
          );
        })} */}
        <Option value="javascript">JavaScript</Option>
        {/* {languages.map((lang) => (
          <Option value={lang}>{lang}</Option>
        ))} */}
      </Select>
    </>
  );
}
