import React, { useEffect, useRef, useState } from 'react';
import { FileSevice } from "../../service/fileService";
import { Tabs } from 'antd';
import backGrountTemplate from "../../assets/images/background.png";
import { useLocation } from 'react-router-dom';
import { urlMainPageOffline } from '../../config/urlConfig';
import { message } from 'antd';
import "./VideoPage.scss";
import {
  Player,
  ControlBar,
  ReplayControl,
  PlaybackRateMenuButton,
} from "video-react";
import HLSSource from "./HLSSource";


const { TabPane } = Tabs;


const PRIVATE_KEY = "4924vyF471fc394";

const jwt = require("jsonwebtoken");

const DemoVideo = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  jwt.verify(token, PRIVATE_KEY);




  const checkToken = (token) => {
    try {
      jwt.verify(token, PRIVATE_KEY);

    } catch {
      message.info("Đã hết thời gian xem. Bạn sẽ được chuyển tới trang chủ CyberSoft !")
      setTimeout(() => {
        window.location = urlMainPageOffline

      }, 5000);
    }
  }
  checkToken(token);


  const [listVideo, setListVideo] = useState([]);
  useEffect(() => {
    FileSevice.layDanhSachCauHinhService().then(res => {
      let link = res.data.content.find(n => n.id == "LIST_VIDEO_DEMO");
      setListVideo(JSON.parse(link.noiDung));

    })
  }, [])

  const [source, setSource] = useState("");

  const playerRef = useRef(null);
  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.load();
    }
  }, [source]);
    

  const renderNoiDung = (noiDung, server) => {

    if (server == 0) {
      FileSevice.layDuongDanVideoService(noiDung).then((res) => {
        setSource(res.data);
      }).catch((err) => console.log(err.response));
    } else {
      FileSevice.layDuongDanVideoDigitalService(noiDung).then((res) => {
        setSource(res.data);
      }).catch((err) => console.log(err.response));

    }




  };

  return (
    <div className="h-max-content min-h-screen w-full  flex">

      <div
        style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
        className=" w-full bg-cover p-3 space-y-2 "
        id='video'
      >

        <div className=" row">

          <div className="col-12 col-md-12 ">
            <div className="video-player">
              {source ?
                <Player
                  ref={playerRef}
                  playsInline fluid={false} width={"100%"} height={550}>
                  <HLSSource

                    isVideoChild
                    src={source}
                  />
                  <ControlBar>
                    <PlaybackRateMenuButton
                      rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                      order={10}
                    />
                  </ControlBar>
                </Player> :
                ""
              }
            </div>


          </div>
          <div className="col-md-12 col-12 row">

            {listVideo.map(item => {
              return <div className="bg-gray-500 text-white hover:text-red-300 p-2 m-2 col-xs-12 col-md-2 " >
                <div>
                  <i className="fa fa-video mr-2"></i>
                  <b >{item.tieuDe}</b>
                </div>

                <div className='mt-2'>
                  <button className='btn btn-sm btn-primary mx-2' onClick={() => {
                    FileSevice.layDuongDanVideoService(item.noiDung).then((res) => {
                      setSource(res.data);
                    })
                  }}>Server 1</button>

                  <button className='btn btn-sm btn-danger' onClick={() => {
                    renderNoiDung(item.noiDung, 1)
                  }}> Server 2 </button>
                </div>

              </div>
            })}

          </div>
        </div>
      </div>
    </div>
  )
}

export default DemoVideo