import { baseServiceNew } from "./baseServiceNew";
import { BaseService } from "./baseService";
import { BaseApiOnline } from "./BaseServiceOnline";


const API_URL = "/api/nguoidung";

const API_URL_THONG_TIN_HOC_TAP = "/api/thongtinhoctaphocvien"

const dangNhapFacebookService = (model) => {
  return baseServiceNew.post(`${API_URL}/login`, model);
};

const checkHocVienNuocNgoai = (res) => {

  return baseServiceNew.post(`${API_URL}/check-nuoc-ngoai`,res);
};

// danh gia mentor và GV
const API_URL_DANHGIA = "/api/danhgiamentor";
const layDanhMucDanhGiaService = () => {
  return BaseService.get(`${API_URL_DANHGIA}/lay-muc-danh-gia`);
};

const luuDanhGiaService = (model) => {

  return BaseService.post(`${API_URL_DANHGIA}`, model);
};

const luuDanhGiaServiceV2 = (model) => {

  return BaseService.post(`${API_URL_DANHGIA}/v2/them-danh-gia`, model);
};

const layDanhSachDanhGiaService = () => {
  return BaseService.get(`${API_URL_DANHGIA}`);
};

const layDanhGiaTrongThangHienTai = () => {
  return BaseService.get(`${API_URL_DANHGIA}/lay-danh-gia-mentor-thang-hien-tai`)
}

// diem tich cuc
const giaHanBaiTapBangDiemTichCuc = (model) => {
  return BaseService.post(`${API_URL_THONG_TIN_HOC_TAP}/gia-han-bai-tap-diem-tich-cuc`, model);
};


// voucher
const API_URL_VOUCHER = "/api/voucher_nguoidung";

const layVoucherUserService = (id,maLop) =>{
  return BaseService.get(`${API_URL_VOUCHER}/lay-theo-ma-user/${id}/${maLop}/0`);
}

const themVoucherUserService = (model) =>{
  return BaseService.post(`${API_URL_VOUCHER}`,model);
}

const tangVoucherUserService = (model) =>{
  return BaseService.post(`${API_URL_VOUCHER}/tang-voucher`,model);
} 

// lay cv
const layUserCVService = (userId) =>{
  return BaseService.get(`/api/nguoidung/lay-thong-tin-cv/${userId}`);
}

const layUserCVServiceV2 = () =>{
  return BaseService.get(`/api/nguoidung/v2/lay-thong-tin-cv/`);
}

const nopUserCV_Service = (model) => {
  return BaseService.post(`/api/thongtincv/nop-cv-review`, model)
}

const capNhatThongTinMoRong = (model)=>{
  return BaseService.put(`/api/nguoidung/update-thongtin-morong`,model)
}

// api cyberlearn
// get voucher 
const layVoucherService = () => {
  return BaseService.get(`/api/vatpham`);
};


// get gioi thieu 
const laySanPhamBanService = () => {
  return BaseService.get(`/api/gioithieu`);
};

const layGioiThieuService = (code) => {
  return BaseService.get(`/api/gioithieu/get-gioi-thieu/${code}`);
};
const getLstGTById = (id,code) => {
  return BaseService.get(`/api/gioithieu/byUser?id=${id}&code=${code}`);
};
const getLstGTByIdTime = (code,obj) => {
  return BaseService.post(`/api/gioithieu/byUser/month/${code}`,obj);
};
const getLstGTThangService = (obj) => {
  return BaseService.post(`/api/gioithieu/get-gioi-thieu-month`,obj);
};
export const NguoiDungSevice = {
  layGioiThieuService,
  laySanPhamBanService,
  dangNhapFacebookService,
  layDanhMucDanhGiaService,
  luuDanhGiaService,
  layDanhSachDanhGiaService,
  layVoucherService,
  themVoucherUserService,
  layVoucherUserService,
  tangVoucherUserService,
  layUserCVService,
  capNhatThongTinMoRong,
  luuDanhGiaServiceV2,
  layDanhGiaTrongThangHienTai,
  giaHanBaiTapBangDiemTichCuc,
  layUserCVServiceV2,
  nopUserCV_Service,
  checkHocVienNuocNgoai,
  getLstGTById,
  getLstGTByIdTime,
  getLstGTThangService
};
