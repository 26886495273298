import React, { useState, useEffect } from "react";
import Practices_ChooseAnswer from "../Pratices/Practices_ChooseAnser";
import Practices_ChooseAnswerToFill from "../Pratices/Praticees_ChooseAnserToFill";
import Practices_HTML_CSS from "../Pratices/Practices_HTML_CSS";
import Practices_FillCodeToInput from "../Pratices/Practices_FillCodeToInput";
import Practices_MultipleChoice from "../Pratices/Practices_MultipleChoice";
import _ from "lodash";
import Navigate_Footer_Pratices from "./Navigate_Footer_Pratices";
import "./Praticess.css";
import { useParams } from "react-router-dom";
import { RoadmapService } from "../../../../../../../../../service/roadmapService";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../../../../../../../../../redux/actions/spinnerAction";
import { useDispatch } from "react-redux";
import UserAnswer from "./UserAnswer";
import { Divider, Icon, Result } from "antd";


export default function TracNghiem_Answer({ data }) {
  // console.log('data: ', data);
  let [currentQuestionIndex, setCurrentQuestsionIndex] = useState(0);
  const [allQuestions, setListQuestion] = useState([]);
  const [userAnserList, setUserAnserList] = useState([]);
  const [score, setScore] = useState(null);
  // console.log(userAnserList);
  let dispatch = useDispatch();
  let idRoadmap = useParams().id;
  useEffect(() => {
    dispatch(set_request_spinner_started());

    RoadmapService.getDetailRoadMap(idRoadmap)
      .then((res) => {
        dispatch(set_request_spinner_ended());

        let roadmapList = res.data.content.danhSachBuoiHocTheoSkill;
        let totalSkillQuiz = roadmapList.map((item) => {
          // console.log(item);
          if (item.danhSachTracNghiemTheoSkill.length > 0) {
            return item.danhSachTracNghiemTheoSkill[0];
          }
          return [];
        });
        // console.log(totalSkillQuiz);
        let index = totalSkillQuiz.findIndex((item) => {
          return item.id == data.id;
        });
        // console.log(totalSkillQuiz[index].diem);
        // setScore(totalSkillQuiz[index].diem);
        setScore(data.diem);

        let listQuestionRaw = JSON.parse(data.nhanXet)?.map(
          (item, index) => {
            return {
              id: item.id,
              noiDung: item,
              isCorrect: false,
              userAnsers: [],
            };
          }
        );
        let userAnserList = JSON.parse(
          data.baiLamTracNghiem
        )?.BaiLam;
        // console.log(userAnserList);
        setUserAnserList(userAnserList);
        setListQuestion(listQuestionRaw);
      })
      .catch((err) => {
        dispatch(set_request_spinner_ended());
      });
  }, [data.id, data.nhanXet]);
  useEffect(() => {
    setCurrentQuestsionIndex(0);
  }, [data.id]);

  let handleClickNextQuestion = (value) => {
    let nextIndex = currentQuestionIndex + value;
    if (nextIndex < 0 || nextIndex == allQuestions.length) {
      return;
    }
    setCurrentQuestsionIndex(currentQuestionIndex + value);
  };

  let handle_CheckSingleChoice = (id, value, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (value) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }

    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckMultipleChoice = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];
    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFillInput = (id, userAnsers = []) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };

    let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }

    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;
    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank_CSS = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];
    newAllQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let arrRenderQuestion = [];
  let isDisableNextBtn;

  if (allQuestions) {
    arrRenderQuestion = allQuestions?.map((question, index) => {
      let keyIndex = index + data?.id;
      switch (question?.noiDung.maLoaiBaiTap) {
        case "SINGLE":
          return (
            <Practices_ChooseAnswer
              key={keyIndex}
              question={question}
              handle_CheckSingleChoice={handle_CheckSingleChoice}
              userAnserList={userAnserList}
            />
          );
        case "multiple_choice":
          return (
            <Practices_MultipleChoice
              key={keyIndex}
              handle_CheckMultipleChoice={handle_CheckMultipleChoice}
              question={question}
            />
          );
        case "fill_inblank_css":
          return (
            <Practices_HTML_CSS
              key={keyIndex}
              handle_CheckFinll_IN_Blank_CSS={handle_CheckFinll_IN_Blank_CSS}
              question={question}
            />
          );
        case "fill_inblank":
          return (
            <Practices_ChooseAnswerToFill
              key={keyIndex}
              handle_CheckFinll_IN_Blank={handle_CheckFinll_IN_Blank}
              question={question}
            />
          );
        case "fill_input":
          return (
            <Practices_FillCodeToInput
              key={keyIndex}
              question={allQuestions[currentQuestionIndex]}
              handle_CheckFillInput={handle_CheckFillInput}
            />
          );

        default:
          break;
      }
    });

    let typeQuestion = allQuestions[currentQuestionIndex]?.noiDung.maLoaiBaiTap;
    if (typeQuestion === "SINGLE" || typeQuestion === "multiple_choice") {
      isDisableNextBtn =
        allQuestions[currentQuestionIndex]?.userAnsers?.length === 0;
    }
    if (
      typeQuestion === "fill_inblank_css" ||
      typeQuestion === "fill_inblank"
    ) {
      isDisableNextBtn = !allQuestions[currentQuestionIndex].userAnsers[0];
    }
  }

  

  return (
    <div className="w-full  flex-grow h-full flex flex-col  ">
      <div className="w-full h-full  flex-grow flex flex-col  p-3 relative overflow-y-scroll">
        <div className="w-full">

          <Result
            icon={<Icon  type={score >= 50 ? "smile":"frown"} />}
            status="success"
            title={`${score}/100 điểm`}
            subTitle="Bạn đã hoàn thành bài tập! Hãy xem lại đáp án phía dưới."


          />
          <h2 className="text-xl text-center"></h2>
          {arrRenderQuestion[currentQuestionIndex]}

          <UserAnswer
            question={allQuestions[currentQuestionIndex]}
            userAnswers={userAnserList[currentQuestionIndex]}
          />
        </div>
        <div className=" h-24 w-full"></div>
      </div>
      <Navigate_Footer_Pratices
        current={currentQuestionIndex + 1}
        total={allQuestions.length}
        handleClickNextQuestion={handleClickNextQuestion}
        isDisableBtn={isDisableNextBtn}
        listQuestion={allQuestions}
        dataKhoaHoc={data}
      />{" "}
    </div>
  );
}