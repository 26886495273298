import * as types from '../types';

const initialState = {
    danhMucDanhGia: [],
    dsDanhGia: [],
    voucher: null,
    userCV:null,
    thongTinTaoCV: null,
    dataCvDaDuyet: null,
    dataCvDaNop: null,
    lichSuUpdate: null,
    trangThaiCv: null,
    thongTinLocalCV: {},
    tempalteCv: [],
    maCodeCv: null,
    soLuotXemPublicCvTrongNgay: 0
}

export const nguoiDungReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.LAY_DANH_MUC_DANH_GIA:
            return {
                ...state,
                danhMucDanhGia: action.payload
            }
        case types.LAY_DANH_SACH_DANH_GIA:
           
            return {
                ...state,
                dsDanhGia: action.payload
            }
        case types.LAY_VOUCHER: {

            let voucher = action.payload?.find(n => n.giaTri == "VC_OFF");


            return {
                ...state,
                voucher
            }
        }

        case types.CAP_NHAT_CV_LOCAL:
            return {
                ...state,
                thongTinLocalCV: action.payload
            }
        
        case types.LAY_USER_CV:
            return {
                ...state,
                userCV: action.payload
            }

            case types.LAY_USER_CV_V2:
        
            let newState = {...state}
           
            if (action.payload?.thongTinCVDaNop !== null) {
                // Xu ly migrate data vao 
                const {thongTinCVDaNop} = action.payload

                // Chỉ lấy các loại update cv sau 
                const CONSTANTS = [
                    "NOP_CV_REVIEW",
                  "DUYET_CV",
                    "TU_CHOI_CV"
                  ]

                let isCvDuocDuyet = thongTinCVDaNop.thongTinCVDaDuocDuyet !== null

               

                if (isCvDuocDuyet) {
                    newState.dataCvDaDuyet = JSON.parse(thongTinCVDaNop.thongTinCVDaDuocDuyet)
                    newState.maCodeCv = thongTinCVDaNop.maCodeCv;
                    newState.soLuotXemPublicCvTrongNgay = thongTinCVDaNop.soLuotXemPublicCvTrongNgay;
                }
                
                newState.lichSuUpdate = (JSON.parse(thongTinCVDaNop.lichSuUpdateCV))?.filter((item) => CONSTANTS.includes(item.LoaiLog))

                newState.trangThaiCv = thongTinCVDaNop.trangThaiCV

               

                newState.thongTinLocalCV = JSON.parse(thongTinCVDaNop.thongTinCVChuaDuyet)
            }

                newState.thongTinTaoCV = action.payload.thongTinTaoCV 
            
               
            return newState
            // return {
            //     ...state,
            //     thongTinTaoCV: action.payload.thongTinTaoCV
            // }
        default:
            return state;
    }
}


export default nguoiDungReducer;