import React, { useRef, memo, useMemo, useEffect, useState } from "react";
import { Progress } from "antd";

let HeaderPanel = ({ data }) => {
  const [soLuongHoanThanh, setSoLuongHoanThanh] = useState(0);
  useEffect(() => {
    let count = 0;
    data?.danhSachDiem.forEach((item) => {
      if (item.diemBaiTap > 0) {
        count++;
      }
      setSoLuongHoanThanh(count);
    });
  }, [data?.danhSachDiem.length]);

  let percent = Math.floor(
    (soLuongHoanThanh / data?.danhSachDiem.length) * 100
  );
  return (
    <div
      className="  lg:ml-5 w-full h-24  flex items-center justify-between bg-transparent space-x-1
    "
    >
      <div className="flex items-center space-x-3">
        <Progress
          format={(percent) => (
            <span style={{ color: "rgb(117, 95, 211)" }}>{percent}% </span>
          )}
          strokeColor={"rgb( 117, 95, 211)"}
          trailColor={"rgba( 117, 95, 211,0.3)"}
          type="circle"
          className="w-20 lg:w-24 "
          strokeWidth={10}
          percent={percent}
        />
        <p className="text-color-title text-base lg:text-lg">
          {data?.tenSkill}
        </p>
      </div>
      <div className="flex flex-col justify-center items-center space-y-2 lg:space-y-3">
        <p className="text-color-content text-left text-sm lg:text-base">
          Bạn đã hoàn thành{" "}
          <span className="font-medium text-color-blue-white">
            {soLuongHoanThanh}/{data?.danhSachDiem.length}{" "}
          </span>
          bài tập
        </p>
        <div className=" lg:space-x-3 w-64 flex justify-between"></div>
      </div>
    </div>
  );
};

export default HeaderPanel = memo(HeaderPanel);
