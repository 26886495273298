import { Divider } from 'antd'
import React from 'react'
import imgSAA from './SAA.png'
import imgNoiDung from './noi_dung_bai_thi.png'
import history from '../../utilities/history'
import { useParams } from 'react-router-dom'

const ContentStart = ({ }) => {
    const { quizId } = useParams()


    return (
        <div className="flex items-center justify-center min-h-screen mt-5">
            <div className="bg-gray-600  text-white rounded-lg shadow-lg p-5">
                <h4 className="text-center text-base  font-semibold text-yellow-600">Bắt đầu bài luyện thi</h4>
                <h3 className="text-center text-white text-2xl font-bold mb-4">ISTQB® Certified Tester Foundation Level 4.0 (CTFL)</h3>
                <p className='flex justify-center'>
                    <img style={{ width: "25%" }} src={imgSAA} />
                </p>
                <h4 className="font-semibold text-yellow-600 uppercase">Lưu ý:</h4>
                <p className=" mb-6 ">

                    - Bạn có thể tạm dừng bài kiểm tra bất cứ lúc nào và tiếp tục sau.
                    <br />
                    - Bạn có thể làm lại bài kiểm tra nhiều lần tùy ý.
                    <br />
                    - Bạn có thể xem lại đáp án của bài kiểm tra.
                    <br />
                    - Hệ thống sẽ thống kê kết quả bài kiểm tra cho bạn.
                    <br />
                </p>
                <button className="bg-yellow-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded w-full mb-4"
                    onClick={() => {

                        let checkQuizAWS = localStorage.getItem("quiz-saa")

                        if (checkQuizAWS) {

                            checkQuizAWS = JSON.parse(checkQuizAWS)

                            if (!checkQuizAWS.find(n => n.quizId == quizId)) {
                                checkQuizAWS.push({ quizId, userChoice: [], trangThai: 0 })

                                localStorage.setItem("quiz-saa", JSON.stringify(checkQuizAWS))

                                history.push(`/quiz-testing/${quizId}`)
                            }

                        } else {

                            // lần đầu vào
                            localStorage.setItem("quiz-saa", JSON.stringify([{ quizId, userChoice: [], trangThai: 0 }]))

                            history.push(`/quiz-testing/${quizId}`)
                        }

                    }}
                >
                    Bắt đầu
                </button>
                <div className="mb-4">
                    <h4 className="font-semibold uppercase text-yellow-600">Thông tin bài thi </h4>
                    <p className=" text-white">
                        - Bao gồm 40 câu hỏi

                    </p>
                    <p className=" text-white">
                        - 60 phút - Passing Score 65% - 26 câu đúng
                        <i className='text-gray-400'>
                            (Chỉ áp dụng cho kỳ thi thật. Hệ thống luyện thi sẽ không tính thời gian và chấm điểm cho bạn )
                        </i>
                    </p>
                    - Tham khảo thêm: <a href='- Tham khảo thêm: https://www.istqb.org/certifications/certified-tester-foundation-level 
' target='_blank'>www.istqb.org </a>
                </div>
                <div className='mb-4'>
                    <h4 className="font-semibold uppercase text-yellow-600">
                        Nội dung bài thi
                    </h4>
                    <p className='flex justify-center'>
                        <img style={{ width: "100%" }} src={imgNoiDung} />
                    </p>
                </div>
                <div className="mb-4">
                    <h4 className="font-semibold uppercase text-yellow-600">Đáp ứng yêu cầu Doanh nghiệp:
                    </h4>
                    <p className=" text-white">
                        - Hiểu được kiểm thử là gì và tại sao nó mang lại lợi ích.
                    </p>
                    <p className=" text-white">
                        -  Hiểu các khái niệm cơ bản của kiểm thử phần mềm.
                    </p>
                    <p className=" text-white">
                        - Xác định cách tiếp cận kiểm thử và các hoạt động cần triển khai tùy thuộc vào bối cảnh kiểm thử.
                    </p>
                    <p className=" text-white">
                        - Đánh giá và cải thiện chất lượng tài liệu.
                    </p>
                    <p className=" text-white">
                        - Tăng hiệu quả và hiệu suất của quá trình kiểm thử.
                    </p>
                    <p className=" text-white">
                        - Đồng bộ quy trình kiểm thử với vòng đời phát triển phần mềm.
                    </p>
                    <p className=" text-white">
                        - Hiểu các nguyên tắc quản lý kiểm thử.
                    </p>
                    <p className=" text-white">
                        -  Viết và giao tiếp các báo cáo lỗi rõ ràng và dễ hiểu.
                    </p>
                    <p className=" text-white">
                        - Hiểu các yếu tố ảnh hưởng đến priorities và efforts liên quan đến kiểm thử.
                    </p>
                    <p className=" text-white">
                        - Làm việc như một phần của đội ngũ đa chức năng.
                    </p>
                    <p className=" text-white">
                        -  Biết các rủi ro và lợi ích liên quan đến tự động hóa kiểm thử.
                    </p>
                    <p className=" text-white">
                        - Xác định các kỹ năng cần thiết cho kiểm thử.
                    </p>
                    <p className=" text-white">
                        - Hiểu tác động của rủi ro đối với quá trình kiểm thử.
                    </p>
                    <p className=" text-white">
                        -  Báo cáo hiệu quả về tiến độ kiểm thử và chất lượng.
                    </p>

                </div>



            </div>
        </div>

    )
}

export default ContentStart