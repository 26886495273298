import React, { useState } from "react";
import ConTentXemDapAn from "../../ItemRoadMap/ContentModal/ContentTracNghiemCuoiKhoa/XemDapAn/ConTentXemDapAn/ConTentXemDapAn";
import TracNghiem_Start from "../../ItemRoadMap/ContentModal/ContentTracNghiemCuoiKhoa/TracNghiem_Start";
export default function BaiTapTracNghiem({ data }) {
  // console.log(data);
  const [isConfirm, setIsConfirm] = useState(false);
  const [number, setNumber] = useState(10);
  // console.log("number", number);
  const forceRerender = () => {
    setNumber(number + 1);
  };

  const renderContent = () => {
    // console.log(data);

    if (data.isHetHan && !data.diem) {
      return (
        <div className="w-full h-full flex justify-center flex-col items-center text-center text-xl  pb-10 ">
          <span className="text-red-700">Hết hạn</span>
        </div>
      );
    }
    if (data.diem && !data.nhanXet) {
      return (
        <div className="flex flex-col justify-center items-center h-full text-lg">
          <p>{data.tieuDe} </p>
          <div className="space-x-2">
            <i className="fa fa-check text-green-600"></i>
            <span> {data.diem} điểm</span>
          </div>
        </div>
      );
    }
    if (data.soLanNop > 0) {
      return <ConTentXemDapAn data={data} />;
    }
    if (isConfirm) {
      return <TracNghiem_Start data={data} forceRerender={forceRerender} />;
    } else {
      return (
        <div className="w-full h-full flex justify-center flex-col items-center text-center text-xl  pb-10 ">
          <p>Bài kiểm tra trắc nghiệm cuối skill</p>
          <p>
            Mỗi học viên sẽ được làm bài 1 lần duy nhất
            <br />
            Sau khi làm xong hệ thống sẽ tính điểm và điểm này sẽ được lưu trữ
            vào hệ thống
          </p>
          <button
            onClick={() => {
              setIsConfirm(true);
            }}
            className="rounded btn-theme px-5 py-2 transition shadow hover:shadow-lg"
          >
            Bắt đầu
          </button>
        </div>
      );
    }
  };
  return <div style={{ width: "90vw", height: "85vh" }}>{renderContent()}</div>;
}
