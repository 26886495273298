import React, { useEffect, useRef, useState } from "react";
import OptionKhoaHocLienQuan from "./OptionChildItem/OptionNewItemRoadMap";
import DocTaiLieuThamKhao from "../NhiemVuCanHoanThanh/DocTaiLieuThamKhao";
import BaiTapNop from "../NhiemVuCanHoanThanh/BaiTapNop";
import BaiTapTracNghiem from "../NhiemVuCanHoanThanh/BaiTapTracNghiem";
import dataTracNghiem from "./../../../../../../dataFake/dataFake.json";
import VideoXemTruoc from "../NhiemVuCanHoanThanh/VideoXemTruoc";
import { useDispatch, useSelector } from "react-redux";
import BaiTapNopExtra from "../NhiemVuCanHoanThanh/BaiTapNopExtra";
import ContentCapstone from "../../ItemRoadMap/ContentModal/ContentCapstone/ContentCapstone";
import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import { Popover, Tooltip } from "antd";
export default function NewRight({
  data,
  dataHash,
  setIsModalVisible,
  setContentModal,
  setTitleModal,
  isShow,
  indexChuongHoc,
}) {
  const dispatch = useDispatch();
  const [process, setProcess] = useState(0);
  const { id } = useParams();
  const [totalTask, setTotalTask] = useState(0);
  const [countVideoXemTruocTrue, setCountVideoXemTruocTrue] = useState(0);
  const [countTaiLieuThamKhaoTrue, setCountTaiLieuThamKhaoTrue] = useState(0);
  const [countBaiTapTrue, setCountBaiTapTrue] = useState(0);
  const [countCapstoneTrue, setCountCapstoneTrue] = useState(0);
  const [countBaiTapExtraTrue, setCountBaiTapExtraTrue] = useState(0);
  const [countTracNghiemTrue, setCountTracnghiemTrue] = useState(0);
  const { dataTaskRoadMap } = useSelector((state) => state.roadmapReducer);

  const renderTenBaiTap = () => {
    return data.taiLieuBaiTap.map((item, index) => {
      return <p key={index}>{item.tieuDe}</p>;
    });
  };
  const countTaskTrue = (dataTask, state, setState, dataRoadMapItem) => {
    let count = 0;
    for (let key in dataRoadMapItem) {
      if (dataTask.hasOwnProperty(dataRoadMapItem[key]?.id)) {
        if (dataTask[dataRoadMapItem[key]?.id].isHoanThanh === true) {
          count++;
        }
      }
      
    }
    if (
      count === dataRoadMapItem.length &&
      Object.keys(dataTask).length !== 0
    ) {
      setTotalTask(totalTask + 1);
    }
    setState(count);
  };
  const countTotalTaskDone = () => {
    let count = 0;
    for (let key in dataHash) {
      // console.log(dataHash[key]);
      let countItem = 0;
      for (let keyItem in dataHash[key]) {
        const dataItem = dataHash[key];
        // console.log(dataItem[keyItem]);
        if (dataItem[keyItem].isHoanThanh === true) {
          // console.log("first");
          countItem++;
        }
      }
      // console.log(countItem);
      if (
        countItem === Object.keys(dataHash[key]).length &&
        Object.keys(dataHash[key]).length !== 0
      ) {
        // console.log("a");
        count++;
      }
    }
    setTotalTask(count);
    // return count;
  };

  const countTotalTask = () => {
    let count = 0;
    // for (let key in dataHash) {
    //   // console.log(Object.keys(dataHash[key]).length);
    //   if (Object.keys(dataHash[key]).length !== 0) {
    //     count++;
    //   }
    // }
    if(data.videoExtra.length>0) count++
    if(data.taiLieuDocThem.length>0) count++
    if(data.taiLieuBaiTap.length>0) count++
    if(data.taiLieuProjectLamThem.length>0) count++
    if(data.tracNghiem.length>0) count++
    if(data.runCode.length>0) count++
    if(data.taiLieuCapstone.length>0) count++
    return count;
  };

  useEffect(() => {
    // console.log(dataTaskRoadMap.get(data.id.toString()));
    // console.log(dataTaskRoadMap.size !== 0);
    if (dataTaskRoadMap.get(data.id.toString()) && dataTaskRoadMap.size !== 0) {
      countTaskTrue(
        dataTaskRoadMap.get(data.id.toString()).danhSachVideoXemTruoc,
        countVideoXemTruocTrue,
        setCountVideoXemTruocTrue,
        data.videoExtra
      );
      countTaskTrue(
        dataTaskRoadMap.get(data.id.toString()).danhSachTaiLieuExtra,
        countTaiLieuThamKhaoTrue,
        setCountTaiLieuThamKhaoTrue,
        data.taiLieuDocThem
      );

      countTaskTrue(
        dataTaskRoadMap.get(data.id.toString()).danhSachBaiTap,
        countBaiTapTrue,
        setCountBaiTapTrue,
        data.taiLieuBaiTap
      );
      countTaskTrue(
        dataTaskRoadMap.get(data.id.toString()).danhSachBaiTapExtra,
        countBaiTapExtraTrue,
        setCountBaiTapExtraTrue,
        data.taiLieuProjectLamThem
      );
      countTaskTrue(
        dataTaskRoadMap.get(data.id.toString()).danhSachTracNghiem,
        countTracNghiemTrue,
        setCountTracnghiemTrue,
        data.tracNghiem
      );
      countTaskTrue(
        dataTaskRoadMap.get(data.id.toString()).danhSachCapstone,
        countCapstoneTrue,
        setCountCapstoneTrue,
        data.taiLieuCapstone
      );
      countTotalTaskDone();
    }
  }, [dataTaskRoadMap]);

  const renderDeadlineBaiTapChinh = () => {
    if (data.taiLieuBaiTap.length > 0) {
      // console.log();
      return data.taiLieuBaiTap?.map((item, index) => {
        // console.log(daysUntilDeadline(item.hanNop));

        return (
          <>
            <p key={index} className="mb-0 text-sm opacity-70 text-red-600">
              Bài {index + 1}: {moment(item.hanNop).format("DD/MM/YYYY")}
            </p>
          </>
        );
      });
    }
  };

  const renderDeadlineTracNghiem = () => {
    if (data?.tracNghiem?.length > 0) {
      return (
        <>
          <p className="mb-0 text-sm opacity-70 text-red-600">
            Deadline: {moment(data?.tracNghiem[0].hanNop).format("DD/MM/YYYY")}
          </p>
        </>
      );
    }
  };
  const renderDeadlineCapstone = () => {
    if (data.taiLieuCapstone.length > 0) {
      // console.log();
      return data.taiLieuCapstone.map((item, index) => {
        // console.log(daysUntilDeadline(item.hanNop));

        return (
          <>
            <p key={index} className="mb-0 text-sm opacity-70 text-red-600">
              Bài {index + 1}: {moment(item.hanNop).format("DD/MM/YYYY")}
            </p>
          </>
        );
      });
    }
  };

  const checkHoanThanhVideoXemTruoc = () => {
    for (let i = 0; i < dataTracNghiem.dataVideoXemTruoc.length; i++) {
      if (dataTracNghiem.dataVideoXemTruoc[i].isHoanThanh === true) {
        setProcess((process) => process + 1);
      }
    }
  };
  //này dùng để đếm tổng số task, tổng dựa theo data từ roadmap kp task
  const renderCountTheoTask = (
    string,
    count,
    dataRoadMapItem,
    dataHashTask
  ) => {
    if (dataHash !== undefined) {
      if (Object.keys(dataHash[dataHashTask]).length !== 0) {
        return `${string} ${count}/${dataRoadMapItem.length}`;
      } else {
        return `${string}`;
      }
    } else {
      return `${string}`;
    }
  };

  //render nút làm runcode
  const renderButtonRuncode = () => {
    // check xong hết các câu
    const checkSuccessRunCode = data.runCode?.every(
      (item) => item.diem === 100
    );
    // console.log(checkSuccessRunCode);
    // check chưa làm bt này
    if (!data.runCode[0]?.isHetHan) {
      return (
        <li className="flex justify-start items-cente p-2 rounded cursor-pointer w-max">
          <NavLink
            to={`/run-code/${id}/${data.id}/${indexChuongHoc}`}
            className="flex items-center text-black font-medium opacity-70"
          >
            <div>
              <span className="p-1 rounded bg-blue-theme w-6 h-6 text-xs xl:text-xs flex items-center justify-center text-white mr-2">
                <i className="fa-solid fa-code"></i>
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span>Live coding</span>
              <span>
                Deadline: {moment(data.runCode[0]?.hanNop).format("DD/MM/YYYY")}
              </span>
            </div>
          </NavLink>
        </li>
      );
    } else if (data.runCode[0]?.isHetHan && data.runCode[0]?.diem === 0) {
      return (
        <li className="flex justify-start items-cente p-2 rounded cursor-pointer w-max">
          <Tooltip
            placement="topLeft"
            title="Hết hạn"
            className="flex items-center text-black font-medium opacity-70 cursor-not-allowed"
          >
            <span className="p-1 rounded bg-blue-theme w-6 h-6 text-xs xl:text-xs flex items-center justify-center text-white mr-2">
              <i className="fa-solid fa-code"></i>
            </span>
            Live coding
          </Tooltip>
        </li>
      );
    } else if (data.runCode[0]?.isHetHan && checkSuccessRunCode) {
      return (
        <li className="flex justify-start items-cente p-2 rounded cursor-pointer w-max">
          <NavLink
            to={`/run-code/${id}/${data.id}/${indexChuongHoc}`}
            className="flex items-center text-black font-medium opacity-70"
          >
            <span className="p-1 rounded bg-blue-theme w-6 h-6 text-xs xl:text-xs flex items-center justify-center text-white mr-2">
              <i className="fa-solid fa-code"></i>
            </span>
            Run Code
          </NavLink>
        </li>
      );
    }
    // check het han nhung da lam bt
    // if (data.runCode[0]?.isHetHan && data.runCode[0]?.diem == 100) {
    //   return (
    //     <li className="flex justify-start items-cente p-2 rounded cursor-pointer w-max">
    //       <Tooltip
    //         placement="topLeft"
    //         title="Prompt Text"
    //         className="flex items-center text-black font-medium opacity-70"
    //       >
    //         <span className="p-1 rounded bg-blue-theme w-6 h-6 text-xs xl:text-xs flex items-center justify-center text-white mr-2">
    //           <i className="fa-solid fa-code"></i>
    //         </span>
    //         Run Code
    //       </Tooltip>
    //     </li>
    //   );
    // }
  };

  useEffect(() => {
    checkHoanThanhVideoXemTruoc();
  }, []);
  // console.log(data);
  return isShow ? (
    <div className="card_theme rounded-3xl min-h-280  flex justify-center items-center xl:py-5 px-3 w-72 xl:w-84 h-max-content">
      <div>
        <h2 className="flex text-xs xl:text-sm items-center mb-3">
          <span className="p-1 w-6 h-6 text-xs xl:text-sm flex justify-center items-center rounded bg-blue-theme text-white mr-2">
            <i className="fa-solid fa-laptop-code"></i>
          </span>
          Các nhiệm vụ hoàn thành {totalTask} / {countTotalTask()}
        </h2>
        <div className="xl:ml-5 ml-2">
          <ul className="space-y-2 mb-0">
            {data.videoExtra.length > 0 && (
              <li className="flex justify-start items-center px-2 hover:bg-gray-200 rounded cursor-pointer w-max">
                <OptionKhoaHocLienQuan
                  icon={<i className="fa-solid fa-video"></i>}
                  title={renderCountTheoTask(
                    "Xem video xem trước",
                    countVideoXemTruocTrue,
                    data.videoExtra,
                    "danhSachVideoXemTruoc"
                  )}
                  statusContent={
                    countVideoXemTruocTrue === data.videoExtra.length ? 1 : 2
                  }
                  lopHocActive={data.videoExtra.length > 0 ? true : false}
                  setTitleModal={setTitleModal}
                  setIsModalVisible={setIsModalVisible}
                  setContentModal={setContentModal}
                  contentModal={
                    <VideoXemTruoc
                      lopHocActive={data.videoExtra.length > 0 ? true : false}
                      maBuoiHoc={data.id}
                      data={data.videoExtra}
                      dataHash={dataHash}
                    />
                  }
                />
              </li>
            )}
            {data.taiLieuDocThem.length > 0 && (
              <li className="flex justify-start items-center px-2 hover:bg-gray-200 rounded cursor-pointer w-max">
                <OptionKhoaHocLienQuan
                  icon={<i className="fa-solid fa-book-open"></i>}
                  title={renderCountTheoTask(
                    "Đọc tài liệu tham khảo",
                    countTaiLieuThamKhaoTrue,
                    data.taiLieuDocThem,
                    "danhSachTaiLieuExtra"
                  )}
                  statusContent={
                    countTaiLieuThamKhaoTrue === data.taiLieuDocThem.length
                      ? 1
                      : 2
                  }
                  lopHocActive={data.taiLieuDocThem.length > 0 ? true : false}
                  setTitleModal={setTitleModal}
                  setIsModalVisible={setIsModalVisible}
                  setContentModal={setContentModal}
                  contentModal={
                    <DocTaiLieuThamKhao
                      lopHocActive={
                        data.taiLieuDocThem.length > 0 ? true : false
                      }
                      data={data.taiLieuDocThem}
                      dataHash={dataHash}
                      idBuoiHoc={data.id}
                    />
                  }
                />
              </li>
            )}
            {data.taiLieuBaiTap.length > 0 && (
              <Popover content={renderTenBaiTap()} trigger="hover">
                <li className="flex justify-start items-center hover:bg-gray-200 px-2 rounded cursor-pointer w-max">
                  <OptionKhoaHocLienQuan
                    icon={<i className="fa-solid fa-code"></i>}
                    title={renderCountTheoTask(
                      "Bài tập chính",
                      countBaiTapTrue,
                      data.taiLieuBaiTap,
                      "danhSachBaiTap"
                    )}
                    deadline={renderDeadlineBaiTapChinh()}
                    statusContent={
                      countBaiTapTrue === data.taiLieuBaiTap.length ? 1 : 2
                    }
                    lopHocActive={data.taiLieuBaiTap.length > 0 ? true : false}
                    setTitleModal={setTitleModal}
                    setIsModalVisible={setIsModalVisible}
                    setContentModal={setContentModal}
                    contentModal={
                      <BaiTapNop
                        data={data.taiLieuBaiTap}
                        dataHash={dataHash?.danhSachBaiTap}
                      />
                    }
                  />
                </li>
              </Popover>
            )}
            {data.taiLieuProjectLamThem.length > 0 && (
              <li className="flex justify-start items-center hover:bg-gray-200 px-2 rounded cursor-pointer w-max">
                <OptionKhoaHocLienQuan
                  icon={<i className="fa-solid fa-chalkboard-user"></i>}
                  title={renderCountTheoTask(
                    "Bài tập extra",
                    countBaiTapExtraTrue,
                    data.taiLieuProjectLamThem,
                    "danhSachBaiTapExtra"
                  )}
                  statusContent={
                    countBaiTapExtraTrue === data.taiLieuProjectLamThem.length
                      ? 1
                      : 2
                  }
                  lopHocActive={
                    data.taiLieuProjectLamThem.length > 0 ? true : false
                  }
                  setTitleModal={setTitleModal}
                  setIsModalVisible={setIsModalVisible}
                  setContentModal={setContentModal}
                  contentModal={
                    <BaiTapNopExtra
                      lopHocActive={
                        data.taiLieuProjectLamThem.length > 0 ? true : false
                      }
                      data={data.taiLieuProjectLamThem}
                      dataHash={dataHash?.danhSachBaiTapExtra}
                      idBuoiHoc={data.id}
                    />
                  }
                />
              </li>
            )}
            {data.tracNghiem.length > 0 && (
              <li className="flex justify-start items-center hover:bg-gray-200 px-2 rounded cursor-pointer w-max">
                <OptionKhoaHocLienQuan
                  icon={<i className="fa-solid fa-pencil"></i>}
                  title={`Bài tập trắc nghiệm`}
                  deadline={renderDeadlineTracNghiem()}
                  lopHocActive={data.tracNghiem.length > 0 ? true : false}
                  statusContent={countTracNghiemTrue === 1 ? 1 : 2}
                  setTitleModal={setTitleModal}
                  setIsModalVisible={setIsModalVisible}
                  setContentModal={setContentModal}
                  contentModal={<BaiTapTracNghiem data={data.tracNghiem[0]} />}
                />
              </li>
            )}
            {/* Bài tập luyện code (Coming soon) */}
            {data.runCode.length > 0 && renderButtonRuncode()}
            {data.taiLieuCapstone.length > 0 && (
              <li className="flex justify-start items-center hover:bg-gray-200 px-2 rounded cursor-pointer w-max">
                <OptionKhoaHocLienQuan
                  icon={<i class="fa-solid fa-bolt-lightning"></i>}
                  title={renderCountTheoTask(
                    "Bài tập Capstone",
                    countCapstoneTrue,
                    data.taiLieuCapstone,
                    "danhSachCapstone"
                  )}
                  classNameTitle={"text-gradient-cyber"}
                  deadline={renderDeadlineCapstone()}
                  lopHocActive={data.taiLieuCapstone.length > 0 ? true : false}
                  statusContent={
                    countCapstoneTrue === data.taiLieuCapstone.length ? 1 : 2
                  }
                  setTitleModal={setTitleModal}
                  setIsModalVisible={setIsModalVisible}
                  setContentModal={setContentModal}
                  contentModal={
                    <>
                      {data.taiLieuCapstone.map((item) => {
                        return (
                          <ContentCapstone
                            dataRoadMapItem={data.taiLieuCapstone}
                            data={item}
                            dataHash={dataHash}
                          />
                        );
                      })}
                    </>
                  }
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <div className=" w-60 xl:w-84 h-32 min-h-280"></div>
  );
}
