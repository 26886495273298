import React, { Component } from "react";
import { Menu, Dropdown, Button } from "antd";
import "./Navbar.scss";
import history from "../../utilities/history";
import { LocalStorageUtil } from "../../utilities/localStorage";

export default class Navbar extends Component {
  onLogout = () => {
    LocalStorageUtil.removeLoginLocalStorage();
    LocalStorageUtil.removeCourseLocalStorage();
    history.push("/login");
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item>
          <a>Thông tin cá nhân</a>
        </Menu.Item>
        <Menu.Item>
          <a>Thống kê</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={this.onLogout}>Thoát</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <nav className="navbar navbar-expand-md bg-light justify-between ">
        <div>
          {/* <a><i className="fa fa-bars"></i></a> */}
          {this.props.tenLop && (
            <p className="m-0 font-medium text-black">
              Lớp: {this.props.tenLop}
            </p>
          )}
        </div>
        <Dropdown overlay={menu} placement="topRight">
          <div className="dropdown-button">
            <img
              width="30"
              src="https://www.seekpng.com/png/full/428-4287240_no-avatar-user-circle-icon-png.png"
            />
            <b>Cybersoft</b>
          </div>
        </Dropdown>
      </nav>
    );
  }
}
