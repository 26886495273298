import React, { useState } from "react";
import ViewPDFVinhDanh from "../ChungNhanPDF/viewPDFVinhDanh/ViewPDFVinhDanh";
import { Link, useParams, useLocation } from "react-router-dom";
import LinkChungNhan from "../ChungNhanPDF/linkChungNhan/LinkChungNhan";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton
} from "react-share";
import ChungNhan from "./ChungNhan";
import VinhDanh from "./VinhDanh";
import { Tooltip } from "antd";
import { DOMAIN_MAIN } from "../../../../config/urlConfig";
import bgImage from '../../../../assets/images/background.png';
import { PDFDownloadLink } from "@react-pdf/renderer";
import ContentChungNhan from "../ChungNhanPDF/ContentChungNhan";

export default function ViewXemChungNhan() {
  const [mask, setMask] = useState({
    maskDark: {

      background: 'rgba(0, 0, 0, 0.8)',
      width: '100%',
      height: '100%',
      position: 'fixed',
      zIndex: '49',

    },
    shareDark: {

      background: 'rgb(255,255,255,0.5)',
      padding: '5px',
      borderRadius: '10px'
    },
    visibleTooltip: true
  })

  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let id = params.get('inf');

  let urlShare = `${DOMAIN_MAIN}/certification/?inf=${id}`;

  function decodeObject(encodedString) {
    //replace white space as +

    let newEncodedString = encodedString.replace(" ", "+");

    // Decode the base64-encoded string to a UTF-8 string
    const decoded = atob(newEncodedString);
    // Decode the UTF-8 string to an object
    return JSON.parse(decodeURIComponent(escape(decoded)));
  }

  return (
    <>
      <div style={mask.maskDark} onClick={() => setMask({
        maskDark: null,
        shareDark: null,
        visibleTooltip: false,
      })}></div>


      <div
        className="w-full"
        style={{
          background: `url(${bgImage}) `,
          backgroundSize: 'contain'
        }}
      >
        <div className="social_share fixed bottom-5 right-4 z-50 w-max " style={mask.shareDark}>
          <div className="share mb-3 gap-3 flex flex-col ">
            <Tooltip placement="topLeft" title="Khoe ngay phần thưởng với bạn bè bạn nhé." visible={mask.visibleTooltip}>
              <FacebookShareButton
                url={urlShare}
                quote="Chia sẻ lên Facebook "
              >
                <div className="rounded-full bg-blue-600 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-700 cursor-pointer">
                  <i className="fa-brands fa-facebook text-white text-2xl"></i>
                </div>
              </FacebookShareButton>
            </Tooltip>
            <FacebookMessengerShareButton
              url={urlShare}
              quote="Chia sẻ lên Messenger"
            >
              <div className="rounded-full bg-blue-600 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-700 cursor-pointer">
                <i className="fa-brands fa-facebook-messenger text-white text-2xl"></i>
              </div>
            </FacebookMessengerShareButton>
            <TwitterShareButton
              url={urlShare}
            >
              <div className="rounded-full bg-blue-500 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-600 cursor-pointer">
                <i className="fa-brands fa-twitter text-white text-2xl"></i>
              </div>
            </TwitterShareButton>
            <TelegramShareButton url={urlShare} quote="Chia sẻ lên Telegram">
              <div className="rounded-md bg-blue-700 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-800 cursor-pointer">
                <i className="fa-brands fa-telegram text-white text-xl"></i>
              </div>
            </TelegramShareButton>

            <PDFDownloadLink
              document={<ContentChungNhan chungNhan={decodeObject(id)} />}
              fileName="certification_cybersoft.pdf"
            >
              <div className="rounded-md bg-blue-700 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-800 cursor-pointer">
                <i class="fa-sharp fa-solid fa-download text-white"></i>
              </div>
            </PDFDownloadLink>


          </div>
        </div>
        <ChungNhan chungNhanId={id} />

      </div>
    </>
  );
}
