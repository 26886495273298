import { Divider } from 'antd'
import React from 'react'
import imgSAA from './SAA.png'
import history from '../../utilities/history'
import { useParams } from 'react-router-dom'

const ContentStart = ({ }) => {
    const { quizId } = useParams()


    return (
        <div className="flex items-center justify-center min-h-screen mt-5">
            <div className="bg-gray-600  text-white rounded-lg shadow-lg p-5">
                <h4 className="text-center text-base  font-semibold text-yellow-600">Bắt đầu bài luyện thi</h4>
                <h3 className="text-center text-white text-2xl font-bold mb-4">AWS Solutions Architect Associate</h3>
                <p className='flex justify-center'>
                    <img style={{width:"25%"}} src={imgSAA} />
                </p>
                <h4 className="font-semibold text-yellow-600 uppercase">Lưu ý:</h4>
                <p className=" mb-6 ">

                    - Bạn có thể tạm dừng bài kiểm tra bất cứ lúc nào và tiếp tục sau.
                    <br />
                    - Bạn có thể làm lại bài kiểm tra nhiều lần tùy ý.
                    <br />
                    - Bạn có thể xem lại đáp án của bài kiểm tra.
                    <br />
                    - Hệ thống sẽ thống kê kết quả bài kiểm tra cho bạn.
                    <br />
                </p>
                <div className="mb-4">
                    <h4 className="font-semibold uppercase text-yellow-600">Thông tin bài thi </h4>
                    <p className=" text-white">
                        - Bao gồm 65 câu hỏi - 130 phút - điểm đậu 750/1000  <i className='text-gray-400'>(Chỉ áp dụng cho kỳ thi thật. Hệ thống luyện thi sẽ không tính thời gian và chấm điểm cho bạn )</i>
                    </p>
                    - Tham khảo thêm: <a href='https://aws.amazon.com/vi/certification/certified-solutions-architect-associate/' target='_blank'> aws.amazon.com </a>
                </div>


                <div>
                    <h4 className="font-semibold mb-4 uppercase text-yellow-600">Domain <i className='text-gray-400 lowercase'>(Các lĩnh vực có trong đề bài)</i></h4>
                    <div className="flex justify-between ">
                        <span>Domain 1: Design Resilient Architectures</span>
                        <span>30%</span>
                    </div>
                    <Divider />
                    <div className="flex justify-between  ">
                        <span>Domain 2: Design Performant Architectures</span>
                        <span>26%</span>
                    </div>
                    <Divider />
                    <div className="flex justify-between ">
                        <span>Domain 3: Design Secure Applications and Architectures</span>
                        <span>24%</span>
                    </div>
                    <Divider />
                    <div className="flex justify-between">
                        <span>Domain 4: Design Cost-Optimized Architectures</span>
                        <span>20%</span>
                    </div>
                    <Divider />
                </div>
                <button className="bg-yellow-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded w-full"
                    onClick={() => {

                        let checkQuizAWS = localStorage.getItem("quiz-saa")

                        if (checkQuizAWS) {

                            checkQuizAWS = JSON.parse(checkQuizAWS)

                            if (!checkQuizAWS.find(n => n.quizId == quizId)) {
                                checkQuizAWS.push({ quizId, userChoice: [], trangThai: 0 })

                                localStorage.setItem("quiz-saa", JSON.stringify(checkQuizAWS))

                                history.push(`/quiz-test/${quizId}`)
                            }

                        } else {

                            // lần đầu vào
                            localStorage.setItem("quiz-saa", JSON.stringify([{ quizId, userChoice: [], trangThai: 0 }]))

                            history.push(`/quiz-test/${quizId}`)
                        }

                    }}
                >
                    Bắt đầu
                </button>
            </div>
        </div>

    )
}

export default ContentStart