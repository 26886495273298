import {LocalStorageUtil} from './localStorage'
import {NguoiDungSevice} from '../service/nguoiDungService'        

export function forLoopMap(array, inputFunc) {
    let lengthArray = array.length;
    let newArrayMap = [] 
    for(let i = 0; i < lengthArray; i++) {
        newArrayMap.push(inputFunc(array[i], i))
    }

    return newArrayMap
}

export function forLoopFilter(array, checkFunc) {
    let lengthArray = array.length;
    let newArrayMap = [] 
    for(let i = 0; i < lengthArray; i++) {
        if (checkFunc(array[i])) {
            newArrayMap.push(array[i])
        }
    }
    return newArrayMap;
}

export function checkHocVienNuocNgoai(res) {
    let infoNguoiDung = LocalStorageUtil.getLoginLocalStorage();

    NguoiDungSevice.checkHocVienNuocNgoai(res)
    .then((res) => {
    // khac thi moi xu ly
        if (res.data && res.data.content != infoNguoiDung.nuocNgoai) {
            //Convert 0 / 1 to true false
            infoNguoiDung.nuocNgoai = !!res.data.content
            LocalStorageUtil.setLoginLocalStorage(infoNguoiDung)
        }
    })
    .catch((err) => {
        console.log(err)
    })
}