import React, { useEffect, useState } from 'react'
import Navbar from '../../template/navbar/Navbar'
import SidebarNew from '../../template/SidebarNew/sidebarNew'
import backGrountTemplate from "../../assets/images/background.png";
import { useMeasure } from 'react-use';
import Axios from 'axios';
import HtmlParser from "react-html-parser";
import './quiztest.css'
import { Badge, Divider, Pagination, Popconfirm, Tabs, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import history from '../../utilities/history';
import { NguoiDungSevice } from '../../service/nguoiDungService';
import { LocalStorageUtil } from '../../utilities/localStorage';
import { FacebookMessengerShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton } from 'react-share';
import ContentStart from './ContentStart';
import { useDispatch, useSelector } from 'react-redux';
import { layQuizAwsAction } from '../../redux/actions/baiTapActions';
import TracNghiem_Start from './ContentTracNghiemCuoiKhoa/TracNghiem_Start';
import QuizEnd from './QuizEnd';
import _ from 'lodash'


export const QuizTest = () => {
    const { quizId } = useParams()

    const dispatch = useDispatch();
    const { lstQuizAws } = useSelector(state => state.baiTapReducer)

    let [dataQuiz, setDataQuiz] = useState(null)
    let [indexQuiz, setIndexQuiz] = useState(0)

    useEffect(() => {

        let listQuiz = []
        if (lstQuizAws) {
            listQuiz = JSON.parse(lstQuizAws.data)
        }

        setDataQuiz(listQuiz.find(n => n.id == quizId))
        setIndexQuiz(0)

    }, [lstQuizAws, quizId])


    useEffect(() => {

        dispatch(layQuizAwsAction())

        if (!quizId) {
            history.push("/quiz-test/1")
        }


    }, [])

    let finishQuiz = (trangThai = 1) => {

        let storeQuizAWS = localStorage.getItem("quiz-saa")

        if (storeQuizAWS) {
            storeQuizAWS = JSON.parse(storeQuizAWS)

            let quiz = storeQuizAWS.find(n => n.quizId == quizId)

            if (quiz) {

                quiz.trangThai = trangThai

                localStorage.setItem("quiz-saa", JSON.stringify(storeQuizAWS))
                history.push(`/quiz-test/${quizId}`)
            }
        }
    };

    let listQuiz = []
    if (lstQuizAws) {
        listQuiz = JSON.parse(lstQuizAws.data)
    }

    let checkQuizAWS = localStorage.getItem("quiz-saa")

    if (checkQuizAWS) checkQuizAWS = JSON.parse(checkQuizAWS)
    let quizDetail = checkQuizAWS ? checkQuizAWS.find(n => n.quizId == quizId) : null



    return (
        <div
            style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
            className="h-max-content min-h-screen w-full  flex gioiThieu"
        >

            <div className="w-60">
                <SidebarNew />
            </div>
            <div
                style={{ flexGrow: "1",width:"85%" }}
                className="h-max-cotent flex flex-col  min-h-screen "
            >
                <Navbar />


                <div className=" flex row ">
                    {/* left */}
                    <div className="col-9 p-4">
                        {dataQuiz && quizDetail ?

                            quizDetail.trangThai == 1 ?
                                <QuizEnd finishQuiz={finishQuiz} quizDetail={quizDetail} dataQuiz={dataQuiz} />

                                :

                                < TracNghiem_Start indexQuiz={indexQuiz} allQuestions={dataQuiz.noiDung} setIndexQuiz={setIndexQuiz} finishQuiz={finishQuiz} />

                            :

                            <ContentStart />
                        }
                    </div>

                    {/* right */}
                    <div className="bg-gray-50  col-3 p-4" style={{ height: "100vh" }}>
                        <div className="border-b pb-4 mb-4">
                            <h2 className="text-lg font-semibold">Chọn bộ đề luyện thi</h2>
                        </div>
                        <div className="border-b mb-4">
                            {listQuiz.map(item => {
                                let cssActive = ""

                                if (item.id == quizId) cssActive = " bg-gray-200 "

                                return <a onClick={() => {

                                    history.push(`/quiz-test/${item.id}`)
                                }}>

                                    <p className={` py-2 hover:bg-gray-200 ${cssActive}`} >{item.tieuDe}</p>

                                </a>
                            })}


                        </div>
                        <div className=' mb-4'>
                            <Popconfirm
                                title="Bạn chắc có muốn tiếp tục ?"
                                onConfirm={() => {

                                    let index = checkQuizAWS.findIndex(n => n.quizId == quizId)
                                    checkQuizAWS.splice(index, 1)

                                    localStorage.setItem("quiz-saa", JSON.stringify(checkQuizAWS))

                                    history.push(`/quiz-test/${quizId}`)


                                }}
                            >

                                <button className="btn btn-sm btn-success mr-2">Làm lại</button>
                            </Popconfirm>


                            <Popconfirm
                                title="Bạn chắc có muốn tiếp tục ?"
                                onConfirm={() => {

                                    finishQuiz()


                                }}
                            >
                                <button className="btn btn-sm btn-danger mr-2">Kết thúc</button>
                            </Popconfirm>
                        </div>

                        <div class="row" style={{ gap: 10 }}>
                            {dataQuiz && (checkQuizAWS && checkQuizAWS.find(n => n.quizId == quizId))
                                &&
                                dataQuiz.noiDung.map((item, index) => {

                                    let cssResult = " bg-gray-400 "

                                    if (quizDetail.trangThai != 0) {
                                        let { userChoice } = quizDetail

                                        let userAnwser = userChoice.find(n => n.id == item.id);


                                        if (userAnwser && _.isEqual(_.sortBy(userAnwser?.choice), _.sortBy(item.dapAn))) {
                                            cssResult = " bg-green-400 "
                                        } else {
                                            cssResult = " bg-red-400 "
                                        }
                                    }

                                    let cssActive = ""
                                    if (index == indexQuiz) cssActive = " inputCauBlue "

                                    return <a onClick={() => {
                                        setIndexQuiz(index)
                                    }}>
                                        <div class={`inputCau text-white font-bold w-12 h-12 flex items-center justify-center   ${cssActive} ${cssResult} `} >
                                            {index + 1}

                                        </div>
                                    </a>
                                })
                            }

                        </div>
                    </div>
                </div>


            </div >
        </div >
    )
}
