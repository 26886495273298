import React, { Component } from "react";
import { connect } from "react-redux";
import "./SideBar.scss";
import * as actions from "../../redux/actions/lopHocAction";
import * as khoaHocActions from "../../redux/actions/khoaHocAction";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { NavLink, withRouter } from "react-router-dom";
import { RoadmapService } from "../../service/roadmapService";
import Dropdown_LopHetHan from "./Dropdown_LopHetHan";
import SidebarItem from "./SidebarItem";
import { checkHocVienNuocNgoai } from '../../utilities/utils'
import publicIp from 'public-ip'
import { Menu } from "antd";
const { SubMenu } = Menu;
// maTrangThai =2 =>Đang mở
// maTrangThai =3 =>Kết thúc
class SidebarNew extends Component {
  state = {
    viTri: -1,
    idKhoaHoc: null,
  };



  componentDidMount = () => {
    const idKhoaHoc = this.props.match.params.id;
    this.setState({
      idKhoaHoc: idKhoaHoc,
    });
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    this.props.dispatch(actions.layDanhSachLopHoc(nguoiDung.id));
    this.props.dispatch(actions.layDanhSachBoDe(nguoiDung.id));
    publicIp.v4()
      .then((res) => {
        checkHocVienNuocNgoai(res)
      })

    this.props.dispatch(khoaHocActions.layDanhSachKhoaHocFree());


    // console.log(nguoiDung);
    // if (idKhoaHoc) {
    //   RoadmapService.getThongBaoNo(idKhoaHoc, nguoiDung.token)
    //     .then((res) => {
    //       // console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  };

  renderLopHoc = (dsLopHoc) => {
    const { viTri } = this.state;
    // console.log(viTri);
    return dsLopHoc
      ? dsLopHoc
        .filter((item) => {
          return item.maTrangThai == 2;
        })
        .map((item, index) => {
          return <SidebarItem item={item} index={index} key={index} />;
        })
      : null;
  };

  renderFreeCouseMenu(dsKhoaHocFree) {

    if (dsKhoaHocFree.length === 0) {

      return <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme">
        Bạn đã hết thời gian truy cập vào tài nguyên này !
      </div>
    }

    return <>
      {dsKhoaHocFree.map((item, index) => (<div className="mt-1" key={index}>
        <NavLink to={`/course-free/${item.id}`}>
          <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme">
            <i className="fa fa-star"></i> {item.tenKhoaHoc} - <span className="text-red-500">{item.soLuotXem} lượt xem</span>
          </div>
        </NavLink>
      </div>))}

      <div className="mt-1" key={0}>
        <a href="https://www.youtube.com/watch?v=lMUaLfzOb5I&list=PLwO_PVO47V1ZlPs3CLfSEkII8JdIbU8fn&index=1" target="_blank" >
          <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme">
            <i className="fa fa-star"></i>
            Agile/Scrum Skill dành cho dev pass phỏng vấn
          </div>
        </a >
      </div >
    </>
  }

  render() {
    const { dsLopHoc } = this.props.lopHocProps;
    const { thongTinTaoCV } = this.props.nguoiDungProps;
    const { dsKhoaHocFree } = this.props.khoaHocProps;

    return (
      <div className=" w-52 lg:w-60 h-screen sidebar-new flex-shrink-0 ">
        <div className=" bg-transparent w-52 lg:w-60 fixed top-0 left-0 h-screen overflow-y-scroll scroll-bar">
          <div className="sidebar-header h-32">
            <div className="logo">
              <img src="https://i.imgur.com/lC22izJ.png" />
            </div>
            <span className="text-blue-theme">CYBERSOFT</span>
          </div>
          {/* <ThongBaoNo /> */}
          <div className="line">
            <span></span>
          </div>
          {/* CV online */}

          <div className="sidebar-list space-y-2 p-2">
            {/* <div className="ant-menu rounded card-theme ant-menu-light ant-menu-root ant-menu-inline">
              <NavLink to={`/referral`}>
                <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme relative">
                  <i className="fa fa-money"></i> Nhận ưu đãi
                  <span className="new_badge">New</span>
                </div>
              </NavLink>
            </div> */}

            <Menu
              style={{ width: "100%" }}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              className="rounded card-theme"

            >
              <SubMenu
                
                key="sub4"
                className="nhanUuDai"
                style={{ paddingLeft: "10px" }}
                title={
                  <div >
                    <span className="text-blue-theme font-black">Chia sẻ nhận quà!</span>
                    <span className="new_badge">New</span>
                  </div>
                }
              >

                <NavLink to={`/referral`}>
                  <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme relative">
                    <i className="fa fa-money"></i> Sản phẩm
                    <span className="new_badge">HOT</span>
                  </div>
                </NavLink>
                <NavLink to={`/refer-chart`}>
                  <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme relative">
                    <i className="fa fa-bar-chart"></i> Doanh thu
                  </div>
                </NavLink>
              </SubMenu>
            </Menu>

            <div className="ant-menu rounded card-theme ant-menu-light ant-menu-root ant-menu-inline">

              <a target="_blank" href="https://login.codezuni.com/package" >

                <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme relative">
                  <i className="fa fa-money"></i> Khóa học mới
                  <span className="new_badge">NEW</span>
                </div>
              </a>
            </div>

            <div className="ant-menu rounded card-theme ant-menu-light ant-menu-root ant-menu-inline">
              <NavLink to={`/quan-ly-cv`}>
                <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme">
                  <i className="fa fa-edit"></i> Quản lý CV
                </div>
              </NavLink>
            </div>

            <div className="ant-menu rounded card-theme ant-menu-light ant-menu-root ant-menu-inline">
              <NavLink to={`/blog/1`}>
                <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme">
                  <i className="fa fa-newspaper-o"></i> Bài viết nên đọc
                </div>
              </NavLink>
            </div>

            <Menu
              style={{ width: "100%" }}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              className="rounded card-theme"

            >
              <SubMenu
              className="nhanUuDai"
                key="sub4"
                style={{ paddingLeft: "10px" }}
                title={
                  <div>
                    <span className="text-blue-theme font-black">Chia sẻ miễn phí</span>
                    <span className="new_badge">New</span>
                  </div>
                }
              >

                {this.renderFreeCouseMenu(dsKhoaHocFree)}
              </SubMenu>
            </Menu>
            {this.renderLopHoc(dsLopHoc)}




            <Dropdown_LopHetHan dsLopHoc={dsLopHoc} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lopHocProps: state.lopHocReducer,
    nguoiDungProps: state.nguoiDungReducer,
    khoaHocProps: state.khoaHocReducer
  };
}

export default withRouter(connect(mapStateToProps, null)(SidebarNew));
