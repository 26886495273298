import { CAPSTONE, QUIZ, QUIZ_WRITE } from "../../constant";
let getArrScore = (skill) => {
  let arrScore = [];
  skill.danhSachBuoiHoc.forEach((lesson) => {
    arrScore = [...arrScore, ...lesson.taiLieuBaiTap];
  });
  if (skill.danhSachCapstoneTheoSkill.length > 0) {
    arrScore = [...arrScore, ...skill.danhSachCapstoneTheoSkill];
  }
  if (skill.danhSachTracNghiemTheoSkill.length > 0) {
    arrScore = [...arrScore, ...skill.danhSachTracNghiemTheoSkill];
  }
  return arrScore;
};

export let getDataReportFromRoadmap = (dataRoadMap) => {
  return dataRoadMap?.danhSachBuoiHocTheoSkill?.map((item) => {
    return {
      tenSkill: item.tenSkill,
      arrScore: getArrScore(item),
    };
  });
};

let getScoreWithCapstone = (captonseArr, quizArr, quizWriteArr) => {
  let finalProjectScore = captonseArr[captonseArr.length - 1]?.diem;

  let scoreQuiz = 0;

  quizArr.forEach((item, index) => {
    scoreQuiz += getScoreFromExercise(item);
  });

  scoreQuiz = scoreQuiz / quizArr.length;

  let scoreQuizWrite = 0;

  quizWriteArr.forEach((item) => {
    scoreQuizWrite += getScoreFromExercise(item);
  });
  scoreQuizWrite = scoreQuizWrite / quizWriteArr.length;

  let capStoneScore = 0;
  for (let index = 0; index < captonseArr.length - 1; index++) {
    capStoneScore += captonseArr[index].diem;
  }
  capStoneScore = capStoneScore / (captonseArr.length - 1);

  return (
    finalProjectScore * 0.4 +
    capStoneScore * 0.3 +
    ((scoreQuiz + scoreQuizWrite) / 2) * 0.3
  );
};

let getScoreNotCapstone = (captonseArr, quizArr, quizWriteArr) => {
  let finalProjectScore = getScoreFromExercise(captonseArr[0].diem);

  let scoreQuiz = 0;

  quizArr.forEach((item) => {
    scoreQuiz += getScoreFromExercise(item);
  });
  scoreQuiz = scoreQuiz / quizArr.length;

  let scoreQuizWrite = 0;

  quizWriteArr.forEach((item) => {
    scoreQuizWrite += getScoreFromExercise(item);
  });
  scoreQuizWrite = scoreQuizWrite / quizWriteArr.length;

  return finalProjectScore * 0.7 + ((scoreQuiz + scoreQuizWrite) / 2) * 0.3;
};

export const getScoreReport = (dataReport) => {
  let captonseArr = [];
  let quizArr = [];
  let quizWriteArr = [];

  dataReport?.forEach((skill) => {
    skill.arrScore?.forEach((lesson) => {
      if (lesson.loaiBaiTap == CAPSTONE) {
        captonseArr.push(lesson);
      }
      if (lesson.loaiBaiTap == QUIZ_WRITE) {
        quizWriteArr.push(lesson);
      }
      if (lesson.loaiBaiTap == QUIZ) {
        quizArr.push(lesson);
      }
    });
  });

  let result = 0;
  if (captonseArr.length == 1) {
    result = getScoreNotCapstone(captonseArr, quizArr, quizWriteArr);
  } else {
    result = getScoreWithCapstone(captonseArr, quizArr, quizWriteArr);
  }
  return result;
};

export let getScoreFromExercise = (ex) => {
  return ex.diem > 0 ? ex.diem : 0;
};
