import React from "react";
import { DOMAIN_API_FILE } from "../../../config/urlConfig";

export default function TaiLieuTheoSkill({ data }) {
  return (
    <div className=" w-full rounded-lg card_theme p-3">
      <p className="p-0 text-black">{data.tenSkill}</p>
      <div className="space-y-4">
        {data.danhSachBaiHoc.map((item, index) => {
          return (
            <div
              key={index}
              className=" w-full rounded card_theme p-3 space-x-5 flex items-center justify-between"
            >
              <span className="text-black">{item.tieuDe}</span>
              <button
                className=" flex items-center h-max-content rounded p-1 border-gray-600 w-max flex-shrink-0 border-1 text-color-title cursor-pointer hover:text-gray-900  px-2 transform duration-300 hover:border-gray-500
                "
              >
                <a
                  className="flex justify-center items-center space-x-1 text-color-content"
                  href={`${DOMAIN_API_FILE}/${item.noiDung}`}
                  target="_blank"
                >
                  <i className="fa fa-folder-open"></i>
                  <span>Tài nguyên</span>
                  <i className="fa fa-download"></i>
                </a>
              </button>{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
}
