import { StyleSheet } from "@react-pdf/renderer";

export const stylesChungNhan = StyleSheet.create({
  page: {},
  username: {
    fontFamily: "Lobster",
    color: "rgb(70, 24, 21)",
    fontSize: "1.5cm",
    position: "absolute",
    textAlign: "center",
    width: "100%",

    top: "25%",
  },
  tenChungNhan: {
    fontFamily: "iCielNovecentosans",
    color: "rgb(70, 24, 21)",
    fontSize: "0.8cm",
    position: "absolute",
    textAlign: "center",
    width: "100%",
    top: "60%",
    padding: " 0cm 1.3cm",
  },
  thoiGianDaoTao: {
    fontFamily: "iCielNovecentosans",

    color: "#A4802C",
    fontSize: "0.5cm",
    position: "absolute",
    textAlign: "left",
    marginLeft: "45%",

    width: "100%",
    bottom: "11%",
  },
  diemCuoiKhoa: {
    textAlign: "left",
    marginLeft: "22%",
    fontFamily: "Neutraface",
    color: "rgb(70, 24, 21)",
    fontSize: "2cm",
    position: "absolute",
    width: "100%",
    bottom: "-6%",
  },
  xepLoai: {
    textAlign: "left",
    marginLeft: "66%",
    fontFamily: "iCielNovecentosans",
    color: "#A4802C",
    fontSize: "0.6cm",
    position: "absolute",
    width: "100%",
    bottom: "-3%",
  },
  xepLoaiTA: {
    textAlign: "left",
    marginLeft: "66%",
    fontFamily: "Neutraface",
    color: "rgb(70, 24, 21)",
    fontSize: "0.9cm",
    position: "absolute",
    width: "100%",
    bottom: "2%",
  },
  duration: {
    textAlign: "left",
    marginLeft: "45%",
    fontFamily: "iCielNovecentosans",
    color: "rgb(70, 24, 21)",
    fontSize: "0.5cm",
    position: "absolute",
    width: "100%",
    bottom: "17%",
  },
  soChungNhan: {
    fontFamily: "iCielNovecentosans",
    color: "rgb(70, 24, 21)",
    fontSize: "0.5cm",
    position: "absolute",
    textAlign: "left",
    marginLeft: "40%",
    width: "100%",
    bottom: "-45%",
  },
  ngayCap: {
    fontFamily: "iCielNovecentosansRegular",
    color: "rgb(70, 24, 21)",
    fontSize: "0.5cm",
    position: "absolute",
    textAlign: "left",
    marginLeft: "34%",
    width: "100%",
    bottom: "-49%",
  },
  chuKi: {
    position: "absolute",
    textAlign: "left",
    marginLeft: "50%",
    width: "100%",
    bottom: "-36%",
    objectFit: "contain",
    width: "300px",
    height: "70px",
  },
});
