import * as types from "../types";
import { NguoiDungSevice } from "../../service/nguoiDungService";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "./spinnerAction";

// lay data cv user
export const layUserCV = (userID) => {

  return (dispatch) => {
    NguoiDungSevice.layUserCVService(userID).then(
      (res) => dispatch(
        {
          type: types.LAY_USER_CV,
          payload: res.data.content,
        }
      )
    ).catch((err) => console.log(err.response));
  };
};

// lay data cv user
export const layUserCV_V2 = () => {

  return (dispatch) => {
    // dispatch(set_request_spinner_started())
    NguoiDungSevice.layUserCVServiceV2().then(
      (res) => {
        dispatch(
          {
            type: types.LAY_USER_CV_V2,
            payload: res.data.content,
          }
        )
        // dispatch(set_request_spinner_ended())
      }
    ).catch((err) => {
      console.log(err.response)
      // dispatch(set_request_spinner_ended())
    });
  };
};



// lay data danh muc danh gia
export const layVoucher = () => {

  return (dispatch) => {
    NguoiDungSevice.layVoucherService().then(
      (res) => dispatch(
        {
          type: types.LAY_VOUCHER,
          payload: res.data.content,
        }
      )
    ).catch((err) => console.log(err.response));
  };
};

// lay data danh muc danh gia
export const layDanhMucDanhGia = () => {

  return (dispatch) => {
    NguoiDungSevice.layDanhMucDanhGiaService().then(
      (res) => dispatch(
        {
          type: types.LAY_DANH_MUC_DANH_GIA,
          payload: res.data.content,
        }
      )
    ).catch((err) => console.log(err.response));
  };
};

// lay data LopHoc
export const layDanhSachDanhGia = () => {

  return (dispatch) => {
    NguoiDungSevice.layDanhSachDanhGiaService().then(
      (res) => dispatch(
        {
          type: types.LAY_DANH_SACH_DANH_GIA,
          payload: res.data.content,
        }
      )
    ).catch((err) => console.log(err.response));
  };
};

export const layDanhSachDanhGiaTrongThang = () => {

  return (dispatch) => {
    NguoiDungSevice.layDanhGiaTrongThangHienTai().then(
      (res) => dispatch(
        {
          type: types.LAY_DANH_SACH_DANH_GIA,
          payload: res.data.content,
        }
      )
    ).catch((err) => console.log(err.response));
  };
};